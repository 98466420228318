.blueContainedButton{
    border-radius: 4px;
    background-color: #1c75bc;
    color: #ffffff;
    width: 146px;
    height: 50px;
    font-size: 17px;
    border: unset;
    &:disabled{
        background-color: #cccccc;
    }
    &:hover{
        background-color: darken(#1c75bc, 4%);
    }
}
.paper{
    width: 700px;
    border-radius: 0 !important;
    max-width: 800px !important;
}
.rejectedBy{
    padding: 10px;
    font-size: 16px;
}
.vacancyList{
    padding: 0 !important;
}
.messageContainer{
    border: 1px solid #cccccc;
    padding: 16px !important;
    background-color: #f9f9f9;
}
.messageMode{
    display: flex;
    padding: 16px 0 !important;
    align-items: center;
    .messageModeTitle{
        font-size: 16px;
        font-weight: bold;
    }
    .checkboxContainer{
        margin: 0 20px !important;
    }
}
.messageTitle{
    font-size: 16px;
    font-weight: bold;
}
.messageTitleContainer{
    padding: 0 0 16px !important;
}