.chipStyle{
    border-radius: 2px !important;
    background-color: rgba(187, 107, 217, 0.2) !important;
    height: fit-content !important;
    margin-left: 10px !important;
    padding: -20px !important;
}

.upcomingInterview{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    align-content: center;
    margin-top: 15px;
    padding-bottom: 15px ;
    border-bottom: 2px solid #444444;
}
.interviewautocompleteFilter{
  width: 200px;
}
.buttonsGrid{
  padding: 24px;
}
.filterButton{
  width: 100px;
}
.MuiFormControl-root{
    margin: 20px !important;
}
.upcomingInterviewLast{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    align-content: center;
    margin-top: 15px;
    padding-bottom: 15px ;
}
.tomorrow{
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid grey;
  border-radius: 5px;

}
.MuiPaper-elevation1{
  box-shadow: none !important;
}
.MuiPaper-root{
  background-color:none !important;
}
.list{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items:stretch; */
  width: 100%;
}
.loader{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.today{
  margin-top: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid grey;
  border-radius: 5px;
  margin-bottom: 20px;
}
.noResponse{
  margin-top: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid grey;
  border-radius: 5px;
  margin-bottom: 20px;
}
.NPT{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    align-content: center;
}
h4{
  margin-top: 0 !important;
}
.phoneNumber{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
}
.candidateNumber{
  font-size: 17px;
  margin-left: 10px;
}
.toolTipTitle{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
    align-content: center;
}
.address{
   display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
.time{
  display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
.iconImg{
  height: 17px !important;
  width: 17px !important;
  margin-right: 8px;
}
.status{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content:flex-end;
  align-items: center;
  
}

