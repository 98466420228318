.chipStyle{
    border-radius: 2px !important;
    background-color: rgba(187, 107, 217, 0.2) !important;
    height: fit-content !important;
    margin-left: 10px !important;
    padding: -20px !important;
}
.feedbacks{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    align-content: center;
}
.resultAwaited{
    margin-top: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border: 1px solid grey;
    border-radius: 5px;
}
.MuiPaper-elevation1{
    box-shadow: none !important;
}
.MuiPaper-root{
  background-color:none !important;
}
.list{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:stretch;
  width: 100%;
}
.loader{
    display: flex;
    justify-content: center;
    align-items: center;
}
.roundPending{
    margin-top: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border: 1px solid grey;
    border-radius: 5px;
}
.ActiveFeedback{
    margin-top: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border: 1px solid grey;
    border-radius: 5px;
}
.feedback{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
    margin-top: 15px;
    padding-bottom: 15px ;
    border-bottom: 2px solid #f9f9f9;
}
.feedbackLast{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    align-content: center;
    margin-top: 15px;
    padding-bottom: 15px ;
}
.details{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
    align-content: center;
}
.NPT{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    align-content: center;
}

.feedbackInfo{
   display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    align-content: center;
}

.status{
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-content:flex-end;
  align-items: flex-end;
}
.interviewDate{
    font-size: 14px;
}
a, a:hover, a:focus, a:active {
    text-decoration: none;
    color: inherit;
}
