input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
}

main > div:first-of-type {
  min-width: 100% !important;
  /* min-height: 100vh !important; */
}
/* 
table tr th:nth-child(5) {
  position: sticky;
  z-index: 1070;
}
table tr th:nth-child(6) {
  position: sticky;
  left: 75px;
  z-index: 1070;
}

table tr td:nth-child(5) {
  position: sticky;
  left: 0px;
  z-index: 1070;
  background-color: inherit;
}
table tr td:nth-child(6) {
  position: sticky;
  left: 75px;
  z-index: 1070;
  background-color: inherit;
} */
.experienceContainer{
  border: 1px solid #cccccc;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.experienceContainerlabel{
  font-size: 16px;
  font-weight: bold;
}
.border0{
  border: 0;
}
.payoutData{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0 0 0 !important;
}
.leaderPayoutData{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0 16px 0 !important;
}
/* .nameLink:visited{
  color: #1c75bc;text-decoration: underline;
} */
.nameLink,.nameLink:visited,.nameLink:active,.nameLink:link{
  color: #1c75bc;text-decoration: underline;
}
.blueButtonContained{
  background-color: #1c75bc !important;
  color: #ffffff !important;
}
