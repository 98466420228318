.chipStyle{
    border-radius: 2px !important;
    background-color: rgba(187, 107, 217, 0.2) !important;
    height: fit-content !important;
    margin-left: 10px !important;
    padding: -20px !important;
}
.upcomingJoinings{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    align-content: center;
}
.tomorrow{
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid grey;
  border-radius: 5px;

}
.today{
  margin-top: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid grey;
  border-radius: 5px;
}
.MuiPaper-elevation1{
  box-shadow: none !important;
}
.MuiPaper-root{
  background-color:none !important;
}
.list{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:stretch;
  width: 100%;
}
.noResponse{
  margin-top: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid grey;
  border-radius: 5px;
}
.upcomingJoining{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    align-content: center;
    margin-top: 15px;
    padding-bottom: 15px ;
    border-bottom: 2px solid #b39c9c;
}
.upcomingJoiningLast{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    align-content: center;
    margin-top: 15px;
    padding-bottom: 15px ;
}
.NPT{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    align-content: center;
}
h4{
  margin-top: 0 !important;
}
.phoneNumber{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
}
.candidateNumber{
  font-size: 17px;
  margin-left: 10px;
}
.toolTipTitle{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
    align-content: center;
}
.address{
   display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
.time{
  display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
.iconImg{
  height: 17px !important;
  width: 17px !important;
  margin-right: 8px;
}
.status{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content:flex-end;
  align-items: center;
  
}

