.heading-table{
    text-align: center;
    margin: 10px auto;

}

.profileBasic{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding: 40px 60px 20px 60px ;
    border-bottom: 1px solid #ffffff;
}
.profileBasic img{
    border-radius: 50%;
    width: 160px;
    height: 160px;
}
.profileBasic span{
    margin-top: 8px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
}
.skeltonTabs{
    width: fit-content;
    margin: 80px auto;
}
.boostJob{
    background-color: #00be94 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #ffffff !important;
    margin-bottom: 16px !important;
    min-width: 130px !important;
}
.cancelJob{
    background-color: #ff6767 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #ffffff !important;
    padding: 6px 10px !important;
    min-width: 130px !important;
}
.backButton{
    padding: 0  !important; 
    margin-bottom: 40px !important;
}
.backButton:hover{
    background-color: unset !important;
}
.tabs{
    margin-top: -110px;
}
.tabs .MuiTab-root{
    font-size: 16px !important; 
    font-weight: bold !important;
    max-width: unset !important;
    text-transform: unset ;
}
.tabs .Mui-selected{
    color: #3f51b5;
    text-decoration: underline;

}