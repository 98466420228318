@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .expanded{
      min-height: 100vh !important;
      animation: fadeIn 1s ;
    }

  .hideExpansion{
      height: 0;
      transition: all linear .3s;

  }
  .colRowHide{
      padding: 0 !important;
  }
  .colRowVisible{
    padding:"16px 1px";
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }