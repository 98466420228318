.columnOrder{
    display: flex;
    flex-direction: column;
}
.columnOrder img{
    width: 106px;
    height: 106px;
    border-radius: 50%;
}
.alginItemsCenter{
    align-items: center;
}
.rowOrder{
    display: flex;
    flex-direction: row;
}
.workerModalRightPanel{
    height: 90%;
    width: 630px;
    overflow-y: auto;
}
.WorkerName{
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}
.linkColor{
    color: #455bdb
}
.detailValues{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}
.modalContainer{
    height: 99%;
}
.modalContainer:focus{
    outline: unset ;
}
.lastExpTitle{
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
}
.weight600{
    font-weight: 600;
}
.fitContentWidth{
    width: fit-content;
}
.marginTopBottom{
    margin: 10px 0 !important;
}
.headings{
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}
.borderRight{
    border-right: 1px solid #cccccc;
}
.padding0{
    padding: 0 !important;
}
.padding10{
    padding: 10px !important;
}
.padding20{
    padding: 20px !important;
}
.paddingTop10{
    padding-top: 10px;   
}
.paddingBottom10Only{
    padding: 0 0 10px 0 !important;
}
.contactDetailsPadding{
    padding: 5px 20px;
}
.borderBottom{
    border-bottom: 1px solid #cccccc;
}
.alignself{
    align-self: flex-end;
}
.marginBottom-15{
    margin-bottom: -15px !important;
}
.slotHeading{
    color:#3f51b5;
    font-weight: 600;
}
.stickTop{
    top: 0;
    position: sticky;
    background-color: white;
}
.linkColor{
    color: #1c75bc;
    
}
.linkColor:hover{
    color: #1c75bc;
    text-decoration: underline;
}
.slotContainer{
    min-width: 200px;
}
.borderLeft{
    border-left: 1px solid #cccccc;
}
.paddingLeft10{
    padding-left:10px;
}
.padding5_0_5_20{
    padding: 5px 0 5px 20px !important;
}
.closeButton{
    float: right;
    top: -30px;
    right: -46px;
    padding: 0 !important;
}
.closeButton:hover{
    background-color: unset !important;
}
.styles_Editext__input__1534X{
    width: 165px;
}

.blueOutlineButton{
    width: 190px;
    border-radius: 2px !important;
    border: solid 2px #1c75bc !important;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1c75bc !important;
    margin-bottom: 12px;
    float: right;
}