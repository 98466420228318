.interviewDashboardTabs{
    padding: 0;
    margin-bottom: 0;
}
.interviewDashboardTabs .react-tabs__tab{
    background: #1c75bc !important;
    border-color: transparent !important ;
    color: white !important;
    border-top-left-radius:4px;
    border-top-right-radius:4px;
    margin-right: 8px;
}
.interviewDashboardTabs .react-tabs__tab--selected{
    background: #dddddd !important;
    border-color: transparent !important ;
    color: black !important;
}
.interviewDashboardTabs .react-tabs__tab-panel--selected{
    border: 1px solid #cfcfcf;
    border-radius: 8px;
    border-top-left-radius: unset;
    padding: 15px;
}
.interviewDashboardTabContainer .react-tabs__tab-panel--selected{
    border: 1px solid #cfcfcf;
    border-radius: 8px;
    border-top-left-radius: unset;
    padding: 15px;
}