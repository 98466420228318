.profile-content{
    width: 1280px;
    margin: auto;
}

.main-content {
    top: 4%;
    margin: 42px auto;
    padding: 55px 0px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 18%);
    background-color: #ffffff;
}

.profile-content .profile-image {
    width: 111px;
    height: 111px;
    border-radius: 68px;
    margin: 0 auto;
    display: block;
}

.profile-content .marginTop10 {
    margin-top: 10px !important;
}

.profile-content .user-name {
    color: #455a64;
    font-size: 18px;
    text-align: left;
    font-family: Open Sans;
    font-weight: bold;
    line-height: 1.28;
    letter-spacing: normal;
    margin:  auto;
}

.profile-content .field-content {
    padding: 0 10px;
    margin-bottom: 20px;
}


.profile-content .myProfile-container {
    display: inline-block;
    vertical-align: top;
    padding-right: 10% !important;
}
.profile-content .profile-info span {
    vertical-align: top;
    padding: 0 5px;
}
.profile-content .devider {
    width: 100%;
    height: 0;
    margin: 46px 0;
    border: solid 1px #cccccc;
}

.profile-content .main-content.profile-accordian {
    box-shadow: none;
    padding: 0;
    margin: 15px auto !important;
    background-color:transparent;
}

.profile-content .profile-accordian .MuiAccordion-root, .MuiAccordion-root.Mui-expanded {
    left: 0;
    width: 100%;
    margin: 15px auto !important;
    padding: 10px;
}

.profile-content .MuiAccordionSummary-content.Mui-expanded {
    padding-bottom: 20px;
    border-bottom: 1px #cccccc solid;
}
.profile-accordian .MuiGrid-item {
    display: flex;
}

.profile-content .MuiPaper-elevation1 {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
}

.profile-accordian .profile-document .MuiGrid-item {
    display: block;

}
.profile-content .MuiDivider-vertical {
    width: 2px !important;
    height: 15px !important;
    display: inline-table;
    background-color: #1c75bc !important;
    vertical-align: middle;
}

.profile-content .profile-document span.inline-text {
    width: auto;
    display: inline-block;
    padding: 5px;
    line-height: 20px;
}
.profile-content .vertical-align {
    vertical-align: middle;
}
.profile-content .selectedFile {
    color: green;
}

.profile-content .MuiGrid-item {
    padding: 0;
}
