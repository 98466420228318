.heading-table{
    text-align: center;
    margin: 10px auto;

}

.sortDir.MuiToggleButton-root:hover {
    text-decoration: none;
    background-color:#3f51b5 !important;
}

.sortDir.MuiToggleButton-root {
    color: white;
    background-color: #3f51b5;
}

.MuiToggleButton-root.Mui-selected:hover {
    text-decoration: none;
    background-color:#3f51b5 !important;
}
.sortDir.MuiToggleButton-root.Mui-selected {
    color: white;
    background-color: #3f51b5;
}